<template>
  <div class="container" v-if="step === 2">
    <div class="lucky_list3 clearfix">
      <div class="lpl_userInfo" style="" v-for="(item, index) in nonePrizeUsers" :key="index">
        <img class="lpl_userImage" :src="item.avatar" style="">
        <!-- <p class="lpl_userName" style="">{{ item.name }}</p> -->
      </div>
    </div>
  </div>
  <div class="mask"></div>
  <div id="stop" class="btn_circle" v-if="step === 2" @click="stop">停止</div>
  <div class="lucky_title">{{ pageData.title }}</div>
  <div class="lucky_list clearfix" v-if="step === 1">
    <div class="left lucky_prize">
      <div class="lucky_prize_box">
        <button class="lucky_prize_left  lucky_prize_direction" :class="leftActive" @click="changePrize(-1)"> &lt;
        </button>
        <div data-default="1" class="lucky_prize_picture">
          <img class="lucky_prize_show" :class="item.luckId === prize.luckId ? '' : 'none'" :src="item.goodsPic"
            :alt="item.goodsName" v-for="(item, index) in prizeList" :key="index" />
        </div>
        <button class="lucky_prize_right lucky_prize_direction" :class="rightActive" @click="changePrize(1)">></button>
      </div>
      <div class="lucky_prize_title">{{ prize.luckName }} </div>
      <div class="lucky_prize_title">{{ prize.goodsName }}</div>
      <div class="lucky_setting">
        <span>
          <b class="lucky_number">{{ nonePrizeUsers.length }}</b>
          人参与
        </span>

        <div class="select_box">
          一次抽 {{ prize.luckCount }} 人
        </div>
      </div>
      <div id="open" class="btn_circle btn_start" v-if="step === 1" @click="start">开始</div>
    </div>
    <div class="right lucky_people_list">
      <div class="lucky_people_title">中奖名单</div>
      <div class="lpl_list clearfix">
        <div class="lpl_userInfo" style="" v-for="(item, index) in prizeUsers" :key="index">
          <img class="lpl_userImage" :src="item.avatar" style="">
          <p class="lpl_userName" style="">{{ item.name }}</p>
        </div>
      </div>
      <div class="lpl_list clearfix none">

      </div>
      <div class="lpl_list clearfix none">

      </div>
    </div>
  </div>
</template>

<script>
import * as sceneScreenApi from '@/api/scene/screen'
import * as sceneLuckApi from '@/api/scene/luck'
import * as sceneLuckUserApi from '@/api/scene/luckUser'
import * as activitySignApi from '@/api/activity/activityUserSign'
export default {
  name: 'scree-lucky-index',
  components: {
  },
  data() {
    return {
      id: 0,
      pageData: {},
      prizeList: [],
      prize: {},
      prizeIndex: 0,
      prizeUsers: [],

      nonePrizeUsers: [],

      leftActive: 'active',
      rightActive: 'active',

      step: 1,
      timer: null

    }
  },
  created(option) {
    this.id = this.$route.query.id
    this.getScreenInfo()
  },
  methods: {
    getScreenInfo() {
      const hide = this.$message.loading('请求中..', 0)
      sceneScreenApi.getById(this.id).then(res => {
        hide()
        if (res.code === 0) {
          this.pageData = res.data
          window.document.body.style.backgroundImage = 'url(' + this.pageData.bgPic + ')'
          this.changePrize()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    },
    changePrize(step) {
      if (step) {
        this.prizeIndex += step
        if (this.prizeIndex <= 0) {
          this.prizeIndex = 0
        }
        if (this.prizeIndex >= this.prizeList.length) {
          this.prizeIndex = this.prizeList.length - 1
        }
      }

      if (this.prizeIndex === 0) {
        this.leftActive = ''
        this.rightActive = 'active'
      } else if (this.prizeIndex === this.prizeList.length - 1) {
        this.leftActive = 'active'
        this.rightActive = ''
      } else {
        this.leftActive = 'active'
        this.rightActive = 'active'
      }

      this.getPrize()
    },
    getPrize() {
      const data = { sceneId: this.pageData.sceneId, sort: 'sortNumber', order: 'asc' }
      sceneLuckApi.all(data).then(res => {
        if (res.code === 0) {
          this.prizeList = res.data
          this.prize = this.prizeList[this.prizeIndex]
          this.getPrizeUser()
          this.getNonePrizeSignUser()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getNonePrizeSignUser() {
      clearInterval(this.timer)
      activitySignApi.nonePrizeSignUser({ activityId: this.prize.activityId, sceneId: this.prize.sceneId, luckId: this.prize.luckId }).then(res => {
        if (res.code === 0) {
          this.nonePrizeUsers = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getPrizeUser() {
      sceneLuckUserApi.all({ luckId: this.prize.luckId }).then(res => {
        if (res.code === 0) {
          this.prizeUsers = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    createLuckUser() {
      const data = { activityId: this.prize.activityId, luckId: this.prize.luckId, sceneId: this.prize.sceneId }
      sceneLuckApi.createLuckUser(data).then(res => {
        if (res.code === 0) {
          this.changePrize()
        } else {
          this.$message.error(res.msg)
        }
        this.step = 1
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getRandomNum(min, max) {
      // 返回[min, max]之间的随机数
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    // 随机数函数
    shuffle(arr) {
      // 当前面元素排好后，最后一个元素位置已被确定，故不需要循环到最后一个
      for (var i = 0; i < arr.length - 1; i++) {
        var temp = arr[i]
        var rnd = i + Math.floor(Math.random() * (arr.length - i))
        arr[i] = arr[rnd]
        arr[rnd] = temp
      }
    },
    start() {
      this.step = 2
      this.timer = window.setInterval(() => {
        this.shuffle(this.nonePrizeUsers)
      }, 800)
    },
    stop() {
      clearInterval(this.timer)
      this.createLuckUser()
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  min-height: 100%;
  font-size: 20px;
}

body {
  background: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}

.clearfix:after {
  clear: both;
  display: table;
  content: '';
}

.left {
  float: left;
}

.right {
  float: right
}

.container {
  position: absolute;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  -o-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  height: 60%;
  top: 20%;
}

.element,
.js_current_dom {
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  background-color: #fff;
}

.btn_circle {
  height: 140px;
  width: 140px;
  border: 20px solid #eee;
  line-height: 100px;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #f00;
  cursor: pointer;
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 9;
  color: #fff;
  -webkit-transition: all 0.7s linear;
  -moz-transition: all 0.7s linear;
  -ms-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

.btn_circle:hover {
  background-color: darkorange;
  border-color: #fff;
}

.btn_start {
  position: static;
  margin: 25px auto 0 auto;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  z-index: 999;
}

.lucky_icon {
  height: 70%;
  width: 70%;
  border-radius: 50%;
  margin-left: 15%;
}

.lucky_userInfo {
  position: absolute;
  height: 100px;
  width: 100px;
  color: #fff;
  font-size: 24px;
}

.lucky_list {
  width: 1000px;
  height: 650px;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.lucky_list3 {
  width: 1300px;
  height: 650px;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
}

.lpl_userInfo {
  width: 84px;
  height: 100px;
  text-align: center;
  float: left;
  margin-bottom: 10px;
}

.lpl_userImage {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.lpl_userName {
  margin-top: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #fff;

}

.lucky_userName {
  text-align: center;
  margin-top: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lucky_title {
  text-align: center;
  width: 100%;
  font-size: 50px;
  font-weight: bold;
  color: #ffd700;
  height: 150px;
  line-height: 150px;
  background: url('https://form.teampoint.cn/static/topbg.png') no-repeat center center;
}

.lucky_prize {
  width: 340px;
  margin-right: 10px;
}

.lucky_people_list {
  width: 580px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.lucky_prize_box {
  height: 200px;
  display: flex;
  flex-direction: row;
}

.lucky_prize_direction {
  width: 70px;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 60px;
  text-align: center;

}

.lucky_prize_direction.active {
  color: #f22;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.lucky_prize_picture {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lucky_prize_show {
  max-width: 100%;
  max-height: 100%;
}

.lucky_people_title {
  width: 100%;
  height: 60px;
  background-color: #f22;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  font-weight: bold;
}

.lucky_setting {
  height: 50px;
  background-color: #f22;
  font-size: 16px;
  color: #fff;
  text-align: left;
  line-height: 50px;
  padding: 0 10px;
  font-weight: bold;
  margin-top: 25px;
}

.select_box {
  float: right;
  margin-top: 1px;
}

.select_lucky_number {
  font-size: 22px;
  border: 1px solid #eee;
  outline: none;
}

.lucky_number {
  font-size: 24px;
}

.lucky_prize_title {
  height: 50px;
  background-color: #f22;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  margin-top: 25px;
}

.lpl_list {
  margin-top: 20px;
  height: 480px;
  overflow: auto;
  box-sizing: border-box;
}

.loader_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

.none {
  display: none;
}
</style>
